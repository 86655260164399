.audio-error-cover {
  align-items: center;
  background: var(--background-color);
  color: var(--primary-text-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 8px;
  position: relative;
  text-align: center;
  z-index: 100;
}

.status__audio-player {
  background: var(--background-color);
  box-sizing: border-box;
  cursor: default; /* May not be needed */
  margin-top: 8px;
  overflow: hidden;
  position: relative;
}

.status__audio-player-audio {
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}

.status__audio-player-expand,
.status__audio-player-mute {
  color: var(--primary-text-color);
  opacity: 0.8;
  position: absolute;
  right: 4px;
  text-shadow: 0 1px 1px $base-shadow-color, 1px 0 1px $base-shadow-color;
}

.status__audio-player-expand {
  bottom: 4px;
  z-index: 100;
}

.status__audio-player-mute {
  top: 4px;
  z-index: 5;
}

.detailed,
.fullscreen {
  .audio-player__volume__current,
  .audio-player__volume::before {
    bottom: 27px;
  }

  .audio-player__volume__handle {
    bottom: 23px;
  }
}

.audio-player {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background: $base-shadow-color;
  border-radius: 4px;
  padding-bottom: 44px;
  direction: ltr;

  &.editable {
    border-radius: 0;
    height: 100%;
  }

  .video-player__volume::before,
  .video-player__seek::before {
    background: currentColor;
    opacity: 0.15;
  }

  .video-player__seek__buffer {
    background: currentColor;
    opacity: 0.2;
  }

  .video-player__buttons button {
    color: currentColor;
    opacity: 0.75;

    &:active,
    &:hover,
    &:focus {
      color: currentColor;
      opacity: 1;
    }
  }

  .video-player__time-sep,
  .video-player__time-total,
  .video-player__time-current {
    color: currentColor;
  }

  .video-player__seek::before,
  .video-player__seek__buffer,
  .video-player__seek__progress {
    top: 0;
  }

  .video-player__seek__handle {
    top: -4px;
  }

  .video-player__controls {
    padding-top: 10px;
    background: transparent;
  }
}

.media-spoiler-audio {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  border: 0;
  display: block;
}

.media-spoiler-audio-play-icon {
  border-radius: 100px;
  color: var(--primary-text-color--faint);
  font-size: 36px;
  left: 50%;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
