.explanation-box {
  margin: 5px 20px;
}

.accordion {
  color: var(--primary-text-color);
  padding: 15px 20px;
  font-size: 14px;
  background-color: var(--brand-color--faint);
  border-radius: 8px;
  margin: 0;
  position: relative;

  &__title {
    font-weight: bold !important;
    font-size: 16px !important;
    background: transparent !important;
    color: var(--primary-text-color) !important;
    padding: 0 !important;
    margin: 0 !important;
    text-transform: none !important;
    text-align: left !important;
    display: flex !important;
    align-items: center;
    border: 0;
    width: 100%;

    &::after {
      content: '';
      display: block;
      font-family: ForkAwesome;
      font-size: 20px;
      padding-left: 10px;
      margin-left: auto;
    }
  }

  &__menu {
    position: absolute;
    top: 17px;
    right: 40px;
  }

  &__content {
    height: 0;
    overflow: hidden;
  }

  &--expanded &__title {
    margin-bottom: 10px !important;

    &::after {
      content: '';
    }
  }

  &--expanded &__content {
    height: auto;
  }

  a {
    color: var(--brand-color--hicontrast);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
