.error-boundary {
  box-sizing: border-box;
  min-height: 100vh;
  max-width: 375px;
  text-align: center;
  padding: 10px 10px 80px;

  i.fa.fa-frown-o {
    display: block;
    text-align: center;
    font-size: 70px;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  a {
    color: var(--brand-color);
  }

  > div {
    margin: auto;

    span {
      display: block;
      text-align: center;
      color: var(--primary-text-color--faint);
    }

    a.return-home {
      display: block;
      margin: 15px auto;
      text-align: center;
    }
  }

  p.help-text {
    text-align: left;
    font-style: italic;
    font-size: 14px;
    padding: 20px 10px 0;
    margin-top: 70px;
    opacity: 0.7;
    border-top: 1px solid;
  }
}
