.emoji-react {
  display: inline-block;
  transition: 0.1s;

  &__emoji {
    img {
      width: 20px;
      height: 20px;
      filter: drop-shadow(2px 0 0 var(--foreground-color));
    }
  }

  &__count {
    display: none;
  }

  + .emoji-react {
    margin-right: -8px;
  }
}

.emoji-react--reblogs {
  color: var(--primary-text-color--faint);
  text-decoration: none;
  vertical-align: middle;
  display: inline-flex;

  i.fa {
    color: var(--highlight-text-color);
    font-size: 20px;
    margin-right: 0.2em;
  }
}

.emoji-reacts {
  display: inline-flex;
  flex-direction: row-reverse;
}

.status-interaction-bar {
  margin-right: auto;
  min-height: 20px;
}

.emoji-reacts-container {
  display: inline-flex;
  margin-right: 0.4em;

  &:hover {
    .emoji-react {
      margin: 0;

      &__count {
        display: inline;
      }
    }

    .emoji-reacts__count {
      display: none;
    }
  }
}

.emoji-reacts__count,
.emoji-react__count {
  font-size: 12px;
  font-weight: bold;
  transform: translateY(2px);
}

.emoji-react-selector {
  position: absolute;
  display: flex;
  background-color: var(--foreground-color);
  padding: 5px 8px;
  border-radius: 9999px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: 0.1s;
  z-index: 999;

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  &__emoji {
    display: block;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;

    img {
      width: 30px;
      height: 30px;
      padding: 3px;
      transition: 0.1s;
    }

    &:hover {
      img {
        width: 36px;
        height: 36px;
        padding: 0;
      }
    }
  }
}

.status__action-bar__counter--favourite {
  position: relative;

  @media (max-width: 455px) {
    position: static;
  }
}

.detailed-status__wrapper .emoji-react-selector {
  bottom: 40px;
  right: 10px;
}

.status .emoji-react-selector {
  bottom: 100%;
  left: -20px;

  @media (max-width: 455px) {
    bottom: 31px;
    right: 10px;
    left: auto;
  }
}
