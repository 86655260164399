.emoji-mart {
  font-size: 13px;
  display: inline-block;
  color: var(--primary-text-color);

  &,
  * {
    box-sizing: border-box;
    line-height: 1.15;
  }

  .emoji-mart-emoji {
    padding: 6px;
  }
}

.emoji-mart-bar {
  border: 0 solid var(--background-color);

  &:first-child {
    border-bottom-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-top-width: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
  }
}

.emoji-mart-anchors {
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  color: var(--primary-text-color--faint);
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  flex: 1;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  cursor: pointer;

  &:hover {
    color: var(--primary-text-color--faint);
  }
}

.emoji-mart-anchor-selected {
  color: var(--highlight-text-color);

  &:hover {
    color: var(--highlight-text-color);
  }

  .emoji-mart-anchor-bar {
    bottom: -1px;
  }
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--highlight-text-color);
}

.emoji-mart-anchors {
  i {
    display: inline-block;
    width: 100%;
    max-width: 22px;
  }

  svg {
    fill: currentColor;
    max-height: 18px;
  }
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  max-height: 35vh;
  padding: 0 6px 6px;
  background: var(--foreground-color);
  will-change: transform;

  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background-color: rgba($base-overlay-background, 0.3);
  }
}

.emoji-mart-search {
  padding: 10px;
  padding-right: 45px;
  background: var(--foreground-color);

  input {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-text-color);
    padding: 7px 9px;
    font-family: inherit;
    display: block;
    width: 100%;
    background: var(--background-color);
    border: 1px solid var(--brand-color--faint);
    border-radius: 9999px;

    &::-moz-focus-inner {
      border: 0;
    }

    &::-moz-focus-inner,
    &:focus,
    &:active {
      outline: 0 !important;
    }
  }
}

.emoji-mart-category .emoji-mart-emoji {
  cursor: pointer;

  span {
    z-index: 1;
    position: relative;
    text-align: center;
  }

  &:hover::before {
    z-index: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(var(--background-color_hsl), 0.7);
    border-radius: 100%;
  }
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  span {
    display: block;
    width: 100%;
    font-weight: 500;
    padding: 5px 6px;
    background: var(--foreground-color);
  }
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;

  .fa {
    font-size: 18px;
    width: 22px;
    height: 22px;
    text-align: center;
  }

  span {
    width: 22px;
    height: 22px;
  }
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: var(--primary-text-color--faint);

  .emoji-mart-category-label {
    display: none;
  }

  .emoji-mart-no-results-label {
    margin-top: 0.2em;
  }

  .emoji-mart-emoji:hover::before {
    content: none;
  }
}

.emoji-mart-preview {
  display: none;
}

.emoji-picker-dropdown__menu {
  @include standard-panel;
  background: var(--foreground-color);
  position: absolute;
  margin-top: 5px;
  z-index: 20000;

  .emoji-mart-scroll {
    transition: opacity 200ms ease;
  }

  &.selecting .emoji-mart-scroll {
    opacity: 0.5;
  }
}

.emoji-picker-dropdown__modifiers {
  position: absolute;
  top: 60px;
  right: 11px;
  cursor: pointer;
}

.emoji-picker-dropdown__modifiers__menu {
  position: absolute;
  z-index: 4;
  top: -4px;
  left: -8px;
  background: var(--foreground-color);
  border-radius: 4px;
  box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
  overflow: hidden;

  button {
    display: block;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
      background: hsla(var(--background-color_hsl), 0.4);
    }
  }

  .emoji-mart-emoji {
    height: 22px;
  }
}

.emoji-mart-emoji {
  span {
    background-repeat: no-repeat;
  }
}
